export default class Profile {
    constructor(
        idUser,
        name,   
        last_name, 
        second_lastname,
        description, 
        email,   
        birthdate, 
        desc_sex, 
        address,
        phone
          
    ) {
        this.idUser = idUser ? idUser : '' ,
        this.name = name ? name : '' ,   
        this.last_name = last_name ? last_name : '', 
        this.second_lastname = second_lastname ? second_lastname :'' ,
        this.description = description ? description: '', 
        this.email = email ? email : '',   
        this.birthdate = birthdate ? birthdate : '', 
        this.desc_sex = desc_sex ? desc_sex :'' , 
        this.address = address ? address : '' ,
        this.phone = phone ? phone : ''
    }
}