<template>

          <div class="min-h-full flex flex-col justify-center items-center" :style="`background-image: url('`+bgImageUrl+`')`">
                 <v-loading :is_loading="isloading" />
                 <div class="w-full md:w-1/2 px-5 py-5 md:px-10">
                    <div class="px-2 py-2 md:px-10 bg-black bg-opacity-70 rounded-md">

                    <!--  -->
                        <div class="px-5 mt-5" elevation="2" >
                            <v-card-text >
                                <div class="grid md:flex gap-3 justify-center text-center" >
                                    <span class="text-xl tracking-wider text-gray-200 font-semibold">Verificación de</span>
                                    <span class="text-xl tracking-wider purple-main font-semibold text-gray-200 ">correo electrónico</span>
                                </div>
                                <div>           
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-1 py-2 mt-2">
                                        <div>        

                                        <div v-if="statusResponse.failled" class="bg-control-dark-background border border-control-dark-border
                                            text-white px-4 py-3 rounded relative text-center font-sans text-base tracking-wide">
                                            <span class="block sm:inline text-center">{{ msgFailled }}</span>
                                        </div>

                                         <div class="flex justify-center" v-if="statusResponse.aproved">
                                             <lottie-animation
                                                :path="'lottie/confirmemail.json'" 
                                                :width="250"
                                                :height="250"     
                                                :speed="1"     
                                            />
                                         </div>

                                        <div v-if="statusResponse.aproved" class="">
                                            <p class="text-center text-gray-50 text-lg font-sans tracking-wide">El correo se ah confirmado correctamente. Es hora de disfrutar de 
                                                <strong class="font-bold text-fucsia-lighter font-sans tracking-wide"> Rebyu</strong></p>
                                        </div>                                        

                                        </div>
                                    </div>                   
                                </div>                          
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <div class="w-full md:w-auto grid md:flex  md:justify-end items-center gap-5 gap-y-2">
                                <button v-if="statusResponse.aproved" class="btn-full-fucsia" @click="Ingresar()">
                                    Acceder a Rebyu
                                </button>   
                            </div>               
                            </v-card-actions>
                        </div>
                       
                    <!--  -->

                   </div>
                </div>

          </div>

</template>

<script>

import _service from "../../services/HSuite/HCore/auth/auth.service";
import Profile from "../../models/profile/profile.model";
import { ProfileService } from '../../services/HSuite/Context/hsuite-rebyu-context';



export default {
    name: "checkMail",
    data(){
        return{
            statusResponse :{
                aproved : false,
                failled : false                
            },
            isloading: false,
            bgImageUrl: require('@/assets/login_bg.jpg'),

            msgFailled : "El tiempo para realizar la confirmación expiro.",
        }
    },
    created(){
         this.Init();
    },

    methods:{

        Ingresar: async function() {
            this.$router.push({ name: "login"});
        },

        Init : async function(){

            try {

                if( this.$route.params.param ){
                     let response = await _service.checkMail(this.$route.params.param);
                     if(response.success){
                        
                      let profile = new Profile();
                        let usuario = response.data;
                        profile.idUser = usuario.iduser;
                        profile.name = usuario.name;
                        profile.last_name = usuario.last_name;
                        profile.second_lastname = usuario.second_lastname;
                        profile.description = '';
                        profile.email = usuario.email;
                        profile.birthdate = usuario.birthdate;
                        profile.desc_sex = usuario.description;
                        profile.address = usuario.address;
                        profile.phone = usuario.phone;
                    //     // SE GUARDA EL PERFIL DEL USURIO EN REBYU
                        let responseprofile = await ProfileService.save(profile);
                        if(responseprofile.success) {
                            this.statusResponse.aproved = true;
                        }
                        //Correo verificado correctamente

                       }else{
                        this.statusResponse.failled = true;
                        this.msgFailled = response.message;
                        //Mostrar codigo de error

                      }
                }else{
                    //Verificar que no contiene el token
                }

            } catch (error) {
                console.log(error);
            }

        }
    }
}

</script>
